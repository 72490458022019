var cldData = require("../../../config.json")
var cldImages = cldData.images.resources
var cldImagesFinal = []
var cldPdfsFinals = []

for (let i = 0; i < cldImages.length; i++) {
  cldImagesFinal.push(cldImages[i].public_id)
  if (cldImages[i].format === "pdf") {
    cldPdfsFinals.push(cldImages[i].public_id)
  }
}

export const instructions = {
  name: "instructions",
  label: "Instructions",
  folder: "src/_archives/instructions",
  create: true,
  fields: [
    {
      label: "Order",
      name: "order",
      widget: "number",
      valueType: "int",
      default: 50,
      min: 1
    },
    {
      label: "URL",
      name: "title",
      widget: "string",
      default: "",
      hint: "Subject to change after URL confirmation by SEO"
    },
    {
      label: "Search Heading",
      name: "searchHeading",
      widget: "string",
      required: false
    },
    {
      label: "Instruction Name",
      name: "name",
      widget: "string",
      default: ""
    },
    {
      label: "Instruction Query Title",
      name: "instructionBlockHeading",
      widget: "string",
      default: "",
      hint:
        "General Instructions, Bone Graft Surgery Instructions, etc. Please do not include 'Pre-Operative' or 'Post-Operative' here."
    },
    {
      label: "Pre-operative or Post-operative?",
      name: "preOrPost",
      widget: "select",
      options: ["pre", "post"]
    },
    {
      label: "Cloudinary Instruction Page Main Photo URL",
      name: "mainPhotoPublicId",
      widget: "select",
      options: cldImagesFinal,
      required: false,
      hint: "If no youtube, this is not required"
    },
    {
      label: "YouTube Embed Code",
      name: "youtube",
      widget: "string",
      required: false
    },
    {
      label: "Meta title",
      name: "metaTitle",
      widget: "string"
    },
    {
      label: "Meta description",
      name: "metaDescription",
      widget: "text"
    },
    {
      label: "Instructions PDF Cloudinary URL",
      name: "pdfId",
      widget: "select",
      options: cldPdfsFinals,
      required: false
    },
    {
      label: "Body",
      name: "body",
      widget: "markdown"
    },
    {
      label: "Page Schema",
      name: "schemaPage",
      widget: "text",
      default: "",
      required: false
    }
  ],
  extension: "json",
  format: "json"
}
